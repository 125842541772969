import React from "react";
import { Carousel, SolutionCard } from "@molecules";
import { Container } from "@atoms";

const SolutionsCarousel = ({ solutions }) => {
  return (
    <Container>
      {solutions?.length > 3 ? (
        <Carousel maxVisible={3} showInactive className="gap-5">
          {solutions?.map(s => {
            return <SolutionCard key={s.uid} {...s} />;
          })}
        </Carousel>
      ) : (
        <div className="flex flex-wrap justify-center gap-5">
          {solutions?.map(s => {
            return (
              <div
                className="w-full sm:flex-[0_1_calc(50%-.625rem)] md:w-auto md:flex-[0_1_calc(33.333%-.875rem)]"
                key={s.uid}
              >
                <SolutionCard {...s} />
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
};

export default SolutionsCarousel;
